<template>
    <div>
        <div class="view-comment" :class="{'not-moderated':!comment.moderated}" v-if="commentReady">
            <div class="alert" v-if="comment.moderated === 0">
                <span>This comment is not yet verified by our moderators</span>
            </div>
            <router-link to="/" class="close">
                <svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <path d="M1.84314575,2.70710678 C1.45262146,2.31658249 1.45262146,1.68341751 1.84314575,1.29289322 C2.23367004,0.902368927 2.86683502,0.902368927 3.25735931,1.29289322 L9.5,7.53553391 L15.7426407,1.29289322 C16.133165,0.902368927 16.76633,0.902368927 17.1568542,1.29289322 C17.5473785,1.68341751 17.5473785,2.31658249 17.1568542,2.70710678 L10.2071068,9.65685425 C9.84447708,10.0194839 9.27263169,10.0453861 8.88011403,9.73456061 L8.79289322,9.65685425 L1.84314575,2.70710678 Z" id="Path" fill="#8A8D8E"></path>
                        <path d="M1.84314575,9.70710678 C1.45262146,9.31658249 1.45262146,8.68341751 1.84314575,8.29289322 C2.23367004,7.90236893 2.86683502,7.90236893 3.25735931,8.29289322 L9.5,14.5355339 L15.7426407,8.29289322 C16.133165,7.90236893 16.76633,7.90236893 17.1568542,8.29289322 C17.5473785,8.68341751 17.5473785,9.31658249 17.1568542,9.70710678 L10.2071068,16.6568542 C9.84447708,17.0194839 9.27263169,17.0453861 8.88011403,16.7345606 L8.79289322,16.6568542 L1.84314575,9.70710678 Z" id="Path-Copy" fill="#8A8D8E" transform="translate(9.500000, 12.474874) scale(1, -1) translate(-9.500000, -12.474874) "></path>
                    </g>
                </svg>
            </router-link>
            <div class="header shaded">
                <span :class="'sentiment--' + this.comment.sentiment" v-if="comment.sentiment==6">COVID-19 Comment</span>
                <span :class="'sentiment--' + this.comment.sentiment">{{ this.sentimentText[comment.sentiment] }}</span> <span class="date">{{ comment.created_at }}</span>
                <h1 :class="'sentiment--' + this.comment.sentiment">{{ comment.title }}</h1>

                <ul class="pills">
                    <li v-for="category in comment.categories">{{ category.title }}</li>
                </ul>
            </div>
            <div class="body">
                <h2><span>Action:</span> {{ comment.action.title }}</h2>
                <p v-html="comment.comment"></p>
            </div>
            <div class="footer">
                <div class="btn--inline">
                    <a href="#" :class="{ active: hasUserLikedComment }" class="btn btn-like" v-on:click.stop.prevent="toggleLike"><span v-if="comment.likes_count > 0">{{ comment.likes_count }}</span></a>
                    <div class="collapsable collapsable--row">
                        <div class="icon-tray">
                            <a :href="'https://www.facebook.com/sharer.php?u=' + this.url" id="icon--facebook" class="icon-tray__item" v-on:click.stop.prevent="shareComment"></a>
                            <a :href="'https://twitter.com/intent/tweet?url=' + this.url + '&text=' + this.comment.title + '&via=' + this.provider + '&hashtags=' + this.hash_tags" id="icon--twitter" class="icon-tray__item" v-on:click.stop.prevent="shareComment"></a>
                            <a :href="'https://www.linkedin.com/shareArticle?mini=true&url=' + this.url + '&title=' + this.comment.title + '&summary=' + this.comment.comment + '&source=' + this.provider" id="icon--linkedin" class="icon-tray__item" v-on:click.stop.prevent="shareComment"></a>
                            <a :href="'https://api.whatsapp.com/send?text=' + this.url" id="icon--whatsapp" class="icon-tray__item" v-on:click.stop.prevent="shareComment"></a>
                        </div>
                        <a href="#" class="btn btn-share" v-on:click.stop.prevent="toggleShare">Share this</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                user: window.user,
                user_id: window.user != null ? window.user.id : '',
                url: window.location.href,
                hash_tags: 'CycleWalkSCR',
                provider: 'CycleWalkSCR',
                comment: {
                    title: null,
                    action: {
                        title: ""
                    },
                    categories: [{
                        id: null,
                        title: null
                    }],
                    likes: [],
                    sentiment: null,
                    comment: "",
                    moderated: 1,
                },
                sentimentText: {
                    0: "Negative",
                    1: "Negative",
                    2: "Quite Negative",
                    3: "Neutral",
                    4: "Quite Positive",
                    5: "Positive"
                },
                commentReady: false,
            }
        },
        computed: {
            hasUserLikedComment(){
                return (this.comment.likes.length > 0)? true: false;
            }
        },
        destroyed(){
            this.$store.commit('updateCommentLatLng', null);
        },
        mounted() {
            this.fetchComment();
        },
        created() {
            window.scrollTo(0,0);
        },
        watch: {
            '$route.path': 'fetchComment'
        },
        methods: {
            fetchComment(){
                this.$store.commit('updateCommentLatLng', null);
                axios.get('/api/comments/' + this.$route.params.id).then((response) => {
                    this.comment = response.data;
                    this.commentReady = true;
                    this.$store.commit('updateCommentLatLng', {location : this.comment.location, sentiment: this.comment.sentiment});
                }, (response) => {
                    this.error = response;
                });
            },
            toggleLike(){
                if( !this.hasUserLikedComment ){
                    this.addLike();
                }else{
                    this.removeLike();
                }
            },
            addLike(){
                if(this.user){
                    axios.post('/api/likes', {comment_id: this.comment.id}).then((response) => {
                        this.comment = response.data;
                    }, (response) => {
                        this.error = response;
                    });
                }else{
                    window.location.replace('/login');
                }
            },
            removeLike(){
                if(this.user){
                    axios.delete('/api/likes/' + this.comment.likes[0].id).then((response) => {
                        this.comment = response.data;
                    }, (response) => {
                        this.error = response;
                    });
                }else{
                    window.location.replace('/login');
                }
            },
            toggleShare(event) {
                var content = event.target.previousElementSibling;
                if (content.style.maxWidth){
                    content.style.maxWidth = null;
                    content.style.opacity = 0;
                } else {
                    content.style.maxWidth = content.scrollWidth + "px";
                    content.style.opacity = 1;
                }
            },
            shareComment(event) {
                window.open(event.target.href, "Share Comment Window","menubar=1,resizable=1,width=350,height=450");
            },

        }
    }
</script>

<style lang="scss">
</style>
