<template>
    <header id="header">
	    <div class="container-fluid">
	        <div id="logo">
	            <!-- <router-link to="/">Deetu</router-link> -->
                <a href="/" v-on:click.stop.prevent="flyToLandingPage">Deetu</a>
	        </div>

	        <!-- <nav>
				<ul id="nav">
                    <li v-if="this.user === null">
                        <a href="/login">Login</a>
                    </li>
                    <li v-if="this.user === null">
                        <a href="/register">Register</a>
                    </li>
                    <li v-if="this.user !== null">
                        <a href="/logout" v-on:click.stop.prevent="logout">Logout</a>
                    </li>
                    <li>
                        <router-link to="/add-comment" class="btn btn-orange btn-comment">Add a comment</router-link>
                    </li>
				</ul>
			</nav> -->
	    </div>
	</header>
</template>

<script>
    export default {
    	name: 'site-header',
        data() {
            return {
                user: window.user
            }
        },
        mounted() {

        },
        methods: {
            logout(){
                axios.post('/logout').then((response) => {
                    window.location.replace('/');
                }, (response) => {
                    this.error = response;
                });
            },
            flyToLandingPage() {
                this.$eventHub.$emit('fly-to-landing-page');
            },
        }
    }
</script>
