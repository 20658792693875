window.axios = require('axios');
window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
};

import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';

Vue.use(VueRouter);
Vue.use(Vuex);

var eventHub = new Vue();
Vue.prototype.$eventHub = eventHub;

const PageMap = Vue.component('page-map', require('./components/PageMap.vue').default);
const PageLanding = Vue.component('page-landing', require('./components/PageLanding.vue').default);
const PageAddComment = Vue.component('page-comment', require('./components/PageAddComment.vue').default);
const PageViewComment = Vue.component('page-view-comment', require('./components/PageViewComment.vue').default);

const routes = [
	{ 
		path: '/', 
		component: PageLanding,
		name: 'landing-page',
	},
	{ 
		path: '/add-comment', 
		component: PageAddComment,
		name: 'comment-page',
	},
	{ 
		path: '/comments/:id', 
		component: PageViewComment,
		name: 'comment-view-page',
	},
];

const router = new VueRouter({
	mode: 'history',
  	routes,
  	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
});

const store = new Vuex.Store({
	state: {
		mode: 'view',
		mapLatLng: null,
		commentLatLng: null
	},
	mutations: {
		// When you switch from adding a comment to viewing the markers
		commentMode (state) {
			state.mode = 'comment';
		},
		// When you switch from adding a comment to viewing the markers
		viewMode (state) {
			state.mode = 'view';
		},
		// When the draggable map marker moces
		updateLatLng(state, latLng) {
			state.mapLatLng = latLng;
		},
		// When a comment is clicked on
		updateCommentLatLng(state, latLng){
			state.commentLatLng = latLng;
		}
	}
});

const app = new Vue({
	el: '#app',
  	router,
  	store,
	render: (createElement) => createElement('div', {
	    attrs: {
	        id: 'app',
	    },
	}, [
	    createElement('page-map', {
	        class: {
	            'view': true,
	        }
	    }),
	]),
});