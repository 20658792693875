<template>
    <div>
        <router-link to="/" class="close">
            <svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M1.84314575,2.70710678 C1.45262146,2.31658249 1.45262146,1.68341751 1.84314575,1.29289322 C2.23367004,0.902368927 2.86683502,0.902368927 3.25735931,1.29289322 L9.5,7.53553391 L15.7426407,1.29289322 C16.133165,0.902368927 16.76633,0.902368927 17.1568542,1.29289322 C17.5473785,1.68341751 17.5473785,2.31658249 17.1568542,2.70710678 L10.2071068,9.65685425 C9.84447708,10.0194839 9.27263169,10.0453861 8.88011403,9.73456061 L8.79289322,9.65685425 L1.84314575,2.70710678 Z" id="Path" fill="#8A8D8E"></path>
                    <path d="M1.84314575,9.70710678 C1.45262146,9.31658249 1.45262146,8.68341751 1.84314575,8.29289322 C2.23367004,7.90236893 2.86683502,7.90236893 3.25735931,8.29289322 L9.5,14.5355339 L15.7426407,8.29289322 C16.133165,7.90236893 16.76633,7.90236893 17.1568542,8.29289322 C17.5473785,8.68341751 17.5473785,9.31658249 17.1568542,9.70710678 L10.2071068,16.6568542 C9.84447708,17.0194839 9.27263169,17.0453861 8.88011403,16.7345606 L8.79289322,16.6568542 L1.84314575,9.70710678 Z" id="Path-Copy" fill="#8A8D8E" transform="translate(9.500000, 12.474874) scale(1, -1) translate(-9.500000, -12.474874) "></path>
                </g>
            </svg>
        </router-link>

        <div class="container-fluid form-intro">
            <h1>Add a comment to the map</h1>
            <p>We want to hear about your active travel experiences on our roads and footways. Using the map you can pinpoint part of your journey and make a comment about your experiences at that point.</p>
            <p><strong>All submissions remain anonymous.</strong></p>
            <p><strong>*Response required</strong></p>
        </div>

        <div class="form-item">
            <div class="num">1</div>
            <div>
                <label>Find a location</label>
                <p>Use the search box to find a location</p>
                <div id='geocoder'></div>
                <p class="error-message">{{ error.location[0] }}</p>
            </div>
        </div>

        <the-map v-if="showMap" key="pageAddCommentMap"></the-map>

        <input type="hidden" name="lat" v-model="comment.location.lat">
        <input type="hidden" name="lng" v-model="comment.location.lng">

        <div class="form-item shaded">
            <div class="num">3</div>
            <div>
                <label>Comment Title*</label>
                <p>Enter a title for your comment here (50 characters maximum)</p>
                <input type="text" name="title" maxlength="50" v-model="comment.title">
                <p class="error-message">{{ error.title[0] }}</p>
            </div>
        </div>

        <div class="form-item">
            <div class="num">4</div>
            <div>
                <label>Categories*</label>
                <p>Select the main categories relevant to your comment</p>
                <div class="button-group-pills text-center" data-toggle="buttons">
                    <label v-for="category in categories" :class="{ 'selected' : (comment.categories.indexOf(category.id) !== -1) }">
                        <input type="checkbox" v-model="comment.categories" :value="category.id">
                        <div>{{ category.title }}</div>
                    </label>
                    <p class="error-message">{{ error.categories[0] }}</p>
                </div>
            </div>
        </div>

        <div class="form-item shaded">
            <div class="num">5</div>
            <div><label>How do you feel?*</label>
                <p>Use the slider to indicate how positive or negative you feel about this issue</p>
                <div id="slider" @click="slideToPip"></div>
            </div>
        </div>

        <div class="form-item shaded">
            <div class="num">6</div>
            <div>
                <label>Suggested Action*</label>
                <p>What action needs to be taken here? Select the most relevant from the dropdown menu. More detail can be given in the comment box below</p>
                <select v-model="comment.action_id">
                    <option v-for="action in actions" :value="action.id">{{action.title}}</option>
                </select>
                <p class="error-message">{{ error.action_id[0] }}</p>
            </div>
        </div>

        <div class="form-item">
            <div class="num">7</div>
            <div>
                <label>Comment</label>
                <p>Use the field below to tell us more about this area (600 characters maximum)</p>
                <textarea name="comment" maxlength="600" v-model="comment.comment"></textarea>

                <div class="align-center pad-two">
                    <button :disabled="submitted" class="btn btn-green" v-on:click.stop.prevent="saveComment">Submit Comment</button>
                    <p v-if="submitted">Thanks for your comment, we're taking you to it now... </p>
                    <!--<div v-f="submitted" id="loadingWheelDraw"></div>-->
                    <ul id="errors">
                      <li v-for="er in error" v-if="er[0] != null">{{er[0]}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //var noUiSlider = require('nouislider/distribute/nouislider.min.js');
    import noUiSlider from 'nouislider';
    import {mapState} from 'vuex';
    import TheMap from '../components/TheMap';

    var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
    mapboxgl.accessToken = 'pk.eyJ1Ijoiam9zaC1kZWV0dSIsImEiOiJjazBtNHNxa3UwNjVoM2p0N3Y0czRjaTVxIn0.fuB03tDDzdW3FGFri4J8MA';
    export default {
        components: {
            TheMap,
        },
        data() {
            return {
                showMap: false,
                user: window.user,
                comment: {
                    title: "",
                    action_id: "",
                    sentiment: 2,
                    comment: "",
                    categories: [],
                    location: {
                        lat: null,
                        lng: null
                    }
                },
                slider: null,
                sliderLabels: [
                    'Very Negative',
                    'Quite Negative',
                    'Neutral',
                    'Quite Positive',
                    'Very Positive'
                ],
                actions: window.actions,
                categories: window.categories,
                error: {
                    location: [],
                    title: [],
                    categories: [],
                    action_id: [],
                },
                submitted: false,
            }
        },
        computed:{
            ...mapState({
                mode: state => state.mode,
                mapLatLng: state => state.mapLatLng,
            }),
        },
        watch:{
            mode(newValue, oldValue) {
                this.showMapInComponent();
            },
            mapLatLng(newValue, oldValue) {
                this.comment.location.lat = newValue.lat;
                this.comment.location.lng = newValue.lng;
            },
        },
        destroyed(){
            this.$store.commit('viewMode');
        },
        mounted() {
            this.slider = document.getElementById('slider');
            if(this.slider != null)
            {
              noUiSlider.create(slider, {
                  start: [this.comment.sentiment],
                  step: 1,
                  connect: true,
                  range: {
                      'min': 0,
                      'max': 4
                    },
                  pips:{
                      mode: 'values',
                      values: [0, 1, 2, 3, 4],
                      density: 100,
                      format: {
                          to: value => this.sliderLabels[value] || '',
                          from: label => this.sliderLabels.indexOf(label),
                        }
                    }
                });

                this.slider.noUiSlider.on('change', (value) => {
                  this.comment.sentiment = parseInt(value[0]);
                });
            }
            this.$store.commit('commentMode');
        },
        methods: {
            showMapInComponent() {
                if (this.mode === 'comment' && window.innerWidth <=767) {
                    return this.showMap = true;
                }
                return this.showMap = false;
            },
            saveComment(){
            this.submitted = true;
            //console.log(this.comment.sentiment);
                axios.post('/api/comments', this.comment).then((response) => {
                    // this.post = response.data;
                    // this.$router.push({ name: 'edit-post', params: { group: this.$route.params.group, post: this.post.slug }});
                    if(this.user){
                        window.location.replace('/comments/' + response.data.id);
                    }else{
                        window.location.replace('/register');
                    }
                }, (xhr) => {
                    let errors = xhr.response.data.errors;

                    this.error.location = errors['location.lat'] !== undefined ? errors['location.lat'] : '';
                    this.error.title = errors.title !== undefined ? errors.title : '';
                    this.error.categories = errors.categories !== undefined ? errors.categories : '';
                    this.error.action_id = errors.action_id !== undefined ? errors.action_id : '';
                    this.submitted = false;
                });
            },
            slideToPip(e) {
                if(e.target.dataset.value) {
                    var value = parseInt(e.target.dataset.value)
                    this.slider.noUiSlider.set(value);
                    this.comment.sentiment = value;
                }
            },
        },
    }
</script>

<style lang="scss">
    #slider{
        height: 1rem;
        border-color: transparent;
        width: 85%;
        margin: 0 auto 4rem auto;

        .noUi-connects{
            background-image: linear-gradient(90deg, #EE3E34 0%, #EC671C 19.73%, #F1C544 53.35%, #C3CB04 81.22%, #81BC00 100%);
        }

        .noUi-value{
            font-size: 1.1rem;
            font-weight: bold;
            @media only screen and (max-width: 767px) {
                font-size: 1rem;
            }

            &[data-value="0"]{
                color: #EE3E34;
            }

            &[data-value="1"]{
                color: #EC671C;
            }

            &[data-value="2"]{
                color: #F1C544;
            }

            &[data-value="3"]{
                color: #C3CB04;
            }

            &[data-value="4"]{
                color: #81BC00;
            }
        }


        .noUi-marker{
            bottom: 0;
            display: none;
        }

        .noUi-handle{
            background: transparent url('/images/handle.png') 0 0;
            background-size: contain;
            border: none;
            box-shadow: none;
            width: 22px;
            height: 25px;
            right: -11px;

            &:before, &:after{
                content: none;
            }
        }
    }
</style>
