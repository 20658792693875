<template>
    <div id="map-wrapper">
        <div id="map">
            <div class="deetu-static">
                <a href="https://deetu.com" target="_blank" aria-label="Deetu logo" rel="noopener">
                    <img src="/images/powered_deetu.png" title="Powered by Deetu" />
                </a>
            </div>
            <div id="attribution" class="mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-ctrl-bottom-right mapboxgl-compact">
                <a class="attrib" target="_blank" href="https://deetu.com/" rel="noopener">© Deetu </a> &nbsp;|&nbsp;
                <a class="attrib" target="_blank" href="https://www.mapbox.com/about/maps/" rel="noopener">© Mapbox </a>
                <a class="attrib" target="_blank" href="http://www.openstreetmap.org/about/" rel="noopener">© OpenStreetMap </a>
                <a class="attrib" target="_blank" href="https://www.mapbox.com/map-feedback/" rel="noopener"><b>Improve this map</b></a>
            </div>
            <div id="map-geocoder" class="geocoder map-geocoder" v-show="mode !== 'comment'">
            <button id="legend-btn" class="legend-btn-closed" title="Show map legend" @click="legendShow = !legendShow">
              <span class="material-icons">layers</span><span class="legend-btn-span">Map key</span>
            </button>
            </div>
            <table v-if="legendShow" id="legend-container">
              <tr class="legend-group phase-two-group last-element" title="Toggle Phase Two Comments">
                <th><input type="radio" name="legend-key" class="legend-checkbox" id="phase-two-checkbox" value="phaseTwo" :checked="phase=='phaseTwo'" @click="togglePhases('phaseTwo')"><label for="phase-two-checkbox"></label></th>
                <td class="blue-circle"><div></div><span>Covid-19 Comments</span></td>
              </tr>
              <tr class="legend-group phase-one-group" title="Toggle Phase One Comments">
                <th><input type="radio" name="legend-key" class="legend-checkbox" id="phase-one-checkbox" value="phaseOne" :checked="phase=='phaseOne'" @click="togglePhases('phaseOne')"><label for="phase-one-checkbox"></label></th>
                <td>Phase One Comments</td>
              </tr>
              <tr class="legend-element phase-one-group" title="Very Positive">
                <th></th><td class="green-circle"><div></div><span>Very Positive</span></td>
              </tr>
              <tr class="legend-element phase-one-group" title="Quite Positive">
                <th></th><td class="light-green-circle"><div></div><span>Quite Positive</span></td>
              </tr>
              <tr class="legend-element phase-one-group" title="Neutral">
                <th></th><td class="yellow-circle"><div></div><span>Neutral</span></td>
              </tr>
              <tr class="legend-element phase-one-group" title="Quite Negative">
                <th></th><td class="orange-circle"><div></div><span>Quite Negative</span></td>
              </tr>
              <tr class="legend-element" title="Very Negative">
                <th></th><td class="red-circle"><div></div><span>Very Negative</span></td>
              </tr>
            </table>
        </div>
        <div class="landing-footer map">
                          Built on <a href="https://deetu.com/visualisation/engage/" target="_blank">Deetu Engage</a> -
                          <a href="mailto:engage@deetu.com?Subject=Engage Map Feedback" target="_top"> Provide feedback.</a>
                        </div>
    </div>
</template>

<script>
    var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
    var MapboxGeocoder = require('@mapbox/mapbox-gl-geocoder');

    mapboxgl.accessToken = 'pk.eyJ1Ijoiam9zaC1kZWV0dSIsImEiOiJjazBtNHNxa3UwNjVoM2p0N3Y0czRjaTVxIn0.fuB03tDDzdW3FGFri4J8MA';

    import {mapState} from 'vuex';

    export default {
        created: function() {
            this.$eventHub.$on('fly-to-landing-page', this.flyToLandingPage);
        },
        beforeDestroy: function() {
            this.$eventHub.$off('fly-to-landing-page', this.flyToLandingPage);
        },
        name: 'the-map',
        data(){
            return {
                // values for map center position
                mapCenter : {
                    desktop: {
                        zoom: 9.3,
                        center: [-1.322, 53.487],
                    },
                    mobile: {
                        zoom: 8,
                        center: [-1.332, 53.487],
                    },
                },
                hasNonDraggableMarker: false, // flag to prevent multiple markers from beeing created in comment view
                hasDraggableMarker: false, // flag to prevent multiple markers from beeing created in comment view
                map: null,
                mapLoaded: false,
                commentMarker: null,
                geocoderMapbox: null,
                markers: {},
                markersOnScreen: {},
                bounds : [
                   [-3.0, 52.3], // SW
                   [ 0.1, 54.5], // NE
                ],
                framesPerSecond : 15,
                initialOpacity : 1,
                opacity : 1,
                initialRadius: 12,
                radius: 12,
                maxRadius: 24,
                timer: null,
                colors: ['#C32121', '#C32121', '#EC671C', '#DFB436', '#B8BF08', '#75AA03', '#248DC1'],
                colorsForAnimation: [
                    'rgba(228,77,82,0.8)',
                    'rgba(228,77,82,0.8)',
                    'rgba(255,149,43,0.8)',
                    'rgba(255,204,51,0.8)',
                    'rgba(161,218,96,0.8)',
                    'rgba(93,165,84,0.8)',
                    'rgba(36,141,193, 0.8)',
                ],
                legendShow: false,
                mapLayers: ['covid_comments',
                            'sentiment_circle'],
                phase: "phaseOne",
            }
        },
        computed:{
            ...mapState({
                mode: state => state.mode,
                commentLatLng: state => state.commentLatLng,
            }),
            isMobile: function() {
                if (window.innerWidth <= 767) {
                    return true;
                }
                return false;
            },
            currentZoom: function() {
                if (this.mapLoaded) {
                    return this.map.getZoom();
                }
            }
        },
        watch:{
            mode(newValue, oldValue) {
                if(this.mapLoaded){
                    this.switchState(newValue);
                }
            },
            commentLatLng(newValue, oldValue) {
                if(this.mapLoaded){
                    if(newValue){
                        this.moveToMarker(newValue);
                    }else{
                        this.removeAnimatemarker();
                    }
                }
            },
            currentZoom(newValue, oldValue) {
                if (this.mode !== 'comment') {
                    if (this.hasNonDraggableMarker) {
                        this.removeNonDraggableMarker();
                        this.hasNonDraggableMarker = !this.hasNonDraggableMarker;
                    }
                    if (this.hasDraggableMarker) {
                        this.removeDraggableMarker();
                        this.hasDraggableMarker = !this.hasDraggableMarker;
                    }
                } else {
                    // condition for creating non-draggable marker
                    if (newValue < 14) {
                        if (this.hasDraggableMarker) {
                            this.removeDraggableMarker();
                            this.hasDraggableMarker = !this.hasDraggableMarker;
                        }
                        if (!this.hasNonDraggableMarker) {
                            this.createNonDraggableMarker();
                            this.hasNonDraggableMarker = !this.hasNonDraggableMarker;
                        }
                    } else {
                        if (this.hasNonDraggableMarker) {
                            this.removeNonDraggableMarker();
                            this.hasNonDraggableMarker = !this.hasNonDraggableMarker;
                        }
                        if (!this.hasDraggableMarker) {
                            this.createDraggableMarker();
                            this.hasDraggableMarker = !this.hasDraggableMarker;
                        }
                    }
                }
            },
            phase: function(newValue, oldValue)
            {
              //console.log("New Value: " + newValue + "  Old Value: " +oldValue);
              this.updateMarkers();
            }
        },
        methods: {
            flyToLandingPage() {
                this.map.flyTo({
                    pitch: 0,
                    bearing: 0,
                    zoom: this.isMobile ? this.mapCenter.mobile.zoom : this.mapCenter.desktop.zoom,
                    center: this.isMobile ? this.mapCenter.mobile.center : this.mapCenter.desktop.center,
                    speed: 0.7
                });
                this.$router.push({ name: "landing-page" }).catch(err => {});
            },
            switchState(mode){
                var appViewElement = document.querySelector('#app > .view');
                appViewElement.classList.toggle(mode + "-mode");

                if(mode === 'view'){
                    this.togglePhases(this.phase);
                    if (this.hasNonDraggableMarker) {
                        this.removeNonDraggableMarker();
                        this.hasNonDraggableMarker = !this.hasNonDraggableMarker;
                    }
                    if (this.hasDraggableMarker) {
                        this.removeDraggableMarker();
                        this.hasDraggableMarker = !this.hasDraggableMarker;
                    }
                    appViewElement.classList.remove("comment-mode");
                    appViewElement.classList.add("view-mode");
                    this.updateMarkers();
                }else{
                    this.map.setLayoutProperty('sentiment_circle', 'visibility', 'none');
                    this.map.setLayoutProperty('covid_comments', 'visibility', 'none');
                    var legend = document.getElementById('legend-container');
                    this.legendShow = false;
                    this.removeAnimatemarker();
                    if (this.currentZoom >= 14) {
                        this.createDraggableMarker();
                        this.hasDraggableMarker = !this.hasDraggableMarker;
                    } else {
                        this.createNonDraggableMarker();
                        this.hasNonDraggableMarker = !this.hasNonDraggableMarker;
                    }
                    this.createGeocoder();
                    appViewElement.classList.add("comment-mode");
                    appViewElement.classList.remove("view-mode");
                }
            },
            createDonutChart(props) {
                var offsets = [];
                var counts = [props.sent1, props.sent2, props.sent3, props.sent4, props.sent5, props.sent6];
                var total = 0;
                for (var i = 0; i < counts.length; i++) {
                    offsets.push(total);
                    total += counts[i];
                }
                var fontSize = total >= 1000 ? 16 : total >= 100 ? 20 : 16;
                var r = total >= 1000 ? 40 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
                var r0 = Math.round(r * 0.6);
                var w = r * 2;

                var html = '<svg width="' + w + '" height="' + w + '" viewbox="0 0 ' + w + ' ' + w +
                    '" text-anchor="middle" class="donut" style="font: ' + fontSize + 'px sans-serif">';

                for (i = 0; i < counts.length; i++) {
                    html += this.donutSegment(offsets[i] / total, (offsets[i] + counts[i]) / total, r, r0, this.colors[i+1]);
                }
                html += '<circle cx="' + r + '" cy="' + r + '" r="' + r0 +
                    '" fill="#f6f6f4" /><text dominant-baseline="central" fill="#5f625f" class="donut-text" transform="translate(' +
                    r + ', ' + r + ')">' + total.toLocaleString() + '</text></svg>';

                var el = document.createElement('div');
                el.innerHTML = html;
                return el.firstChild;

            },
            donutSegment(start, end, r, r0, color) {
                if (end - start === 1) end -= 0.00001;
                var a0 = 2 * Math.PI * (start - 0.25);
                var a1 = 2 * Math.PI * (end - 0.25);
                var x0 = Math.cos(a0), y0 = Math.sin(a0);
                var x1 = Math.cos(a1), y1 = Math.sin(a1);
                var largeArc = end - start > 0.5 ? 1 : 0;

                return ['<path d="M', r + r0 * x0, r + r0 * y0, 'L', r + r * x0, r + r * y0,
                    'A', r, r, 0, largeArc, 1, r + r * x1, r + r * y1,
                    'L', r + r0 * x1, r + r0 * y1, 'A',
                    r0, r0, 0, largeArc, 0, r + r0 * x0, r + r0 * y0,
                    '" fill="' + color + '" />'].join(' ');
            },
            updateMarkers() {
                var newMarkers = {};
                var features = this.map.querySourceFeatures(this.phase=='phaseOne' ? 'sentiment_data' : 'covid_data');

                // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
                // and add it to the map if it's not there already
                if(true)
                {
                  for (var i = 0; i < features.length; i++) {
                      var coords = features[i].geometry.coordinates;
                      var props = features[i].properties;

                      if (!props.cluster) continue;
                      var id = props.cluster_id;

                      var marker = this.markers[id];
                      if (!marker) {
                          let el = this.createDonutChart(props);
                          marker = this.markers[id] = new mapboxgl.Marker({element: el}).setLngLat(coords);
                          // Cluster click events

                          el.addEventListener('click', e => {
                              e.stopPropagation();
                              e.preventDefault();

                              // get the position in px from translation
                              let markerCoords = e.target.style.transform
                                          .split('translate')[2]
                                          .split(')')[0]
                                          .replace(/[{()}]/g, '')
                                          .replace(/[{px}]/g, '')
                                          .split(',');
                              let currentZoom = this.map.getZoom();

                              this.map.flyTo({
                                  zoom: currentZoom + 2,
                                  pitch: 0,
                                  bearing: 0,
                                  // Convert above px into map coordinates
                                  center: this.map.unproject(markerCoords),
                                  speed: 0.7
                              });
                          });
                      }
                      newMarkers[id] = marker;

                      if (!this.markersOnScreen[id]) {
                          marker.addTo(this.map);
                      }
                  }
                }
                // for every marker we've added previously, remove those that are no longer visible
                for (id in this.markersOnScreen) {
                    if (!newMarkers[id]) {
                      this.markersOnScreen[id].remove();
                    }
                }
                this.markersOnScreen = newMarkers;
            },
            createGeocoder(){
                let geocoder = document.getElementById('geocoder');

                // if already created destroy and recreated innerElement
                if (geocoder.innerHTML !== '' ) {
                    geocoder.innerHTML = '';
                }
                this.geocoderMapbox = new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    marker: false,
                    bbox: [-3.0, 52.3, 0.1, 54.5],
                    placeholder: "Search location",
                    minLength: 3,
                    limit: 15,
                    zoom: 17,
                    types: 'country,region,postcode,district,place,locality,neighborhood,address',
                });

                document.getElementById('geocoder').appendChild(this.geocoderMapbox.onAdd(this.map));

                this.geocoderMapbox.on('result', (result) => {
                    //console.log(result.result);
                    this.commentMarker.setLngLat(result.result.center);
                    this.$store.commit('updateLatLng', this.commentMarker.getLngLat());
                });
            },
            createNonDraggableMarker() {
                var popupWidth = '370px';
                if (this.isMobile) {
                    popupWidth = '280px';
                }

                var popup = new mapboxgl.Popup({ offset: 20, closeButton: false })
                    .setHTML("<div class='form-item'><div class='num'>2</div><div><label>Zoom in</label><p>Please zoom in to mark the location of your comment.</p></div></div>")
                    .setMaxWidth(popupWidth);

                var marker = this.commentMarker = new mapboxgl.Marker({
                    color: '#8A8D8E',
                    draggable: false,
                }).setLngLat(this.map.getCenter()).setPopup(popup).addTo(this.map);

                this.commentMarker.togglePopup();

                this.map.on('move', function(e) {
                    let currentCenter = e.target.getCenter();
                    marker.setLngLat(currentCenter);
                });
            },
            removeNonDraggableMarker() {
                if (this.commentMarker) {
                    this.commentMarker.remove();
                    this.commentMarker = null;
                }
            },
            createDraggableMarker(){
                var popupWidth = '370px';
                if (this.isMobile) {
                    popupWidth = '280px';
                }

                var popup = new mapboxgl.Popup({ offset: 20, closeButton: false })
                    .setHTML("<div class='form-item'><div class='num'>2</div><div><label>Mark the location</label><p>Please move the map pin to mark the location of your comment.</p></div></div>")
                    .setMaxWidth(popupWidth);

                this.commentMarker = new mapboxgl.Marker({
                    color: '#EC671C',
                    draggable: true
                }).setLngLat(this.map.getCenter()).setPopup(popup).addTo(this.map);

                this.commentMarker.togglePopup();

                this.commentMarker.on('dragend', (e) => {
                    this.$store.commit('updateLatLng', e.target.getLngLat());
                });

            },
            removeDraggableMarker(){
                if(this.commentMarker){
                    this.commentMarker.remove();
                    this.commentMarker = null;
                }
            },
            animateMarker() {
                this.timer = setInterval(function() {

                    this.radius += (this.maxRadius - this.radius) / this.framesPerSecond;
                    this.opacity -= ( .9 / this.framesPerSecond );

                    if (this.opacity <= 0) {
                        this.radius = this.initialRadius;
                        this.opacity = this.initialOpacity;
                    }

                    this.map.setPaintProperty('point', 'circle-radius', this.radius);
                    this.map.setPaintProperty('point', 'circle-opacity', this.opacity);
                }.bind(this), 1000 / this.framesPerSecond);
            },
            removeAnimatemarker(){
                if (this.map.getLayer('point')) {
                    this.map.removeLayer('point');
                    this.map.removeSource('point');
                }

                while (this.timer) {
                    window.clearInterval(this.timer);
                    this.timer--;
                }

                this.radius = this.initialRadius;
                this.opacity = this.initialOpacity;
            },
            moveToMarker(marker){
                if (!this.map.getSource('point')) {
                    this.map.addSource('point', {
                          "type": "geojson",
                          "data": {
                              "type": "Point",
                              "coordinates": marker.location.coordinates
                          }
                    });

                    this.map.addLayer({
                        "id": "point",
                        "source": "point",
                        "type": "circle",
                        "paint": {
                            "circle-radius": this.initialRadius,
                            "circle-radius-transition": {duration: 0},
                            "circle-opacity-transition": {duration: 0},
                            "circle-color": this.colorsForAnimation[marker.sentiment],
                        }
                    });

                    setTimeout(this.animateMarker(), 0);
                }

                this.map.flyTo({
                    zoom: 18.9,
                    pitch: 0,
                    bearing: 0,
                    center: marker.location.coordinates,
                    speed: 0.9
                });
            },
            toggleMapLegend(){
            // Open and close map legend on button click
              var legend = document.getElementById('legend-container');
              if(legend != null)
              {
                //console.log(legend.style.display);
                $('#legend-container').css('display', 'table');
                $(this).removeClass('legend-btn-closed');
                $(this).attr('title', 'Hide map legend');
                $('#legend-container').css('display', 'none');
                $(this).addClass('legend-btn-closed');
                $(this).attr('title', 'Show map legend');
              }
            },
            togglePhases(phase){
              switch(phase)
              {
                case "phaseOne":
                  this.phase = "phaseOne";
                  this.map.setLayoutProperty('sentiment_circle', 'visibility', 'visible');
                  this.map.setLayoutProperty('covid_comments', 'visibility', 'none');
                break;
                case "phaseTwo":
                  this.phase = "phaseTwo";
                  this.map.setLayoutProperty('sentiment_circle', 'visibility', 'none');
                  this.map.setLayoutProperty('covid_comments', 'visibility', 'visible');
                break;
                }
                this.updateMarkers();
                //this.map.flyTo({zoom:this.map.getZoom() + 0.1});
            }
        },
        mounted() {
            this.map = new mapboxgl.Map({
                container: 'map',
                minZoom: 7.5,
                maxZoom: 18.1,
                maxBounds: this.bounds,
                zoom: this.isMobile ? this.mapCenter.mobile.zoom : this.mapCenter.desktop.zoom,
                center: this.isMobile ? this.mapCenter.mobile.center : this.mapCenter.desktop.center,
                attributionControl: false,
                style: 'mapbox://styles/josh-deetu/ck0m53zaz02511ckde4orrq74'
            });

            var that = this;

            var _createClass = (function() {
              function defineProperties(target, props) {
                for (var i = 0; i < props.length; i++) {
                  var descriptor = props[i];
                  descriptor.enumerable = descriptor.enumerable || false;
                  descriptor.configurable = true;
                  if ("value" in descriptor) descriptor.writable = true;
                  Object.defineProperty(target, descriptor.key, descriptor);
                }
              }
              return function(Constructor, protoProps, staticProps) {
                if (protoProps) defineProperties(Constructor.prototype, protoProps);
                if (staticProps) defineProperties(Constructor, staticProps);
                return Constructor;
              };
            })();

            function _classCallCheck(instance, Constructor) {
                if (!(instance instanceof Constructor)) {
                    throw new TypeError("Cannot call a class as a function");
                }
            }

            var StyleToggle = (function() {
              function StyleToggle(_ref) {
                _classCallCheck(this, StyleToggle);
              }

              _createClass(StyleToggle, [
                {
                  key: "onAdd",
                  value: function onAdd(map) {
                    this._map = map;
                    var _this = this;

                    this._btn = document.createElement("button");
                    this._btn.type = "button";
                    this._btn.className = "mapboxgl-ctrl-icon satellite-style-button";
                    this._btn["aria-label"] = "Satellite Toggle";
                    this._btn.title = "Show Satellite";

                    var labels = ['waterway-label','road_major_label number',
                              'road_major_label name','road_major_label street',
                              'poi_label','place_label_other','place_label_cities',
                              'place_label_towns','country_label'];

                    function setSatelliteStyle(map) {
                      // Map Layers
                      map.setPaintProperty('mapbox-satellite', 'raster-opacity', 1);
                      map.setPaintProperty('tunnel_minor', 'line-color', 'rgba(227,227,227,0.3)');
                      map.setPaintProperty('tunnel_major', 'line-color', 'rgba(227,227,227,0.3)');
                      map.setPaintProperty('road_minor', 'line-color', 'rgba(227,227,227,0.3)');
                      map.setPaintProperty('road_major', 'line-color', 'rgba(242,222,156,0.6)');
                      map.setPaintProperty('bridge_minor case', 'line-color', 'rgba(227,227,227,0.3)');
                      map.setPaintProperty('bridge_major case', 'line-color', 'rgba(242,222,156,0.3)');
                      map.setPaintProperty('bridge_minor', 'line-color', 'rgba(227,227,227,0.3)');
                      map.setPaintProperty('bridge_major', 'line-color', 'rgba(242,222,156,0.6)');
                      map.setPaintProperty('rail_minor', 'line-color', 'rgba(227,227,227,0.3)');
                      map.setPaintProperty('rail_major', 'line-color', 'rgba(242,222,156,0.6)');
                      map.setPaintProperty('scr-border', 'line-color', '#463960');
                      map.setPaintProperty('scr-mask', 'fill-color', 'rgba(113, 110, 119, 0.6)');

                      // Map Label Layers
                      for (var i=0;i<labels.length;i++) {
                        map.setPaintProperty(labels[i], 'text-color', '#ffffff');
                        map.setPaintProperty(labels[i], 'text-halo-color', '#2b2b2b');
                      }
                      map.setPaintProperty('waterway-label', 'text-color', '#75cff0');
                      map.setPaintProperty('waterway-label', 'text-halo-width', 0);
                      map.setPaintProperty('poi_label', 'text-color', '#afed82');
                      map.setPaintProperty('country_label', 'text-halo-color', '#050505');
                    }
                    function setLightStyle(map) {
                      // Map Layers
                      map.setPaintProperty('mapbox-satellite', 'raster-opacity', 0);
                      map.setPaintProperty('tunnel_minor', 'line-color', 'rgba(240,240,240,1)');
                      map.setPaintProperty('tunnel_major', 'line-color', 'rgba(240,240,240,1)');
                      map.setPaintProperty('road_minor', 'line-color', 'rgba(255,255,255,1)');
                      map.setPaintProperty('road_major', 'line-color', 'rgba(255,255,255,1)');
                      map.setPaintProperty('bridge_minor case', 'line-color', 'rgba(255,255,255,0.5)');
                      map.setPaintProperty('bridge_major case', 'line-color', 'rgba(255,255,255,0.5)');
                      map.setPaintProperty('bridge_minor', 'line-color', 'rgba(255,255,255,1)');
                      map.setPaintProperty('bridge_major', 'line-color', 'rgba(255,255,255,1)');
                      map.setPaintProperty('rail_minor', 'line-color', 'rgba(230,230,230,1)');
                      map.setPaintProperty('rail_major', 'line-color', 'rgba(230,230,230,1)');
                      map.setPaintProperty('scr-border', 'line-color', '#aea5c0');
                      map.setPaintProperty('scr-mask', 'fill-color', 'rgba(228, 226, 233, 0.6)');

                      // Map Label Layers
                      for (var i=0;i<labels.length;i++) {
                        map.setPaintProperty(labels[i], 'text-color', '#4e315e');
                        map.setPaintProperty(labels[i], 'text-halo-color', 'rgba(255,255,255,0.75)');
                      }
                      map.setPaintProperty('road_major_label number', 'text-halo-color', 'rgba(255,255,255,1)');
                    }

                    this._btn.onclick = function() {
                      var mapSatelliteOpacity = map.getPaintProperty('mapbox-satellite', 'raster-opacity');

                      // if Light, switch to Satellite (sat opacity = 1)
                      // else if Satellite, switch to Light (sat opacity = 0)
                      if (mapSatelliteOpacity == 0) {
                        setSatelliteStyle(map);
                        this.className = "mapboxgl-ctrl-icon light-style-button";
                        this.title = "Hide Satellite";
                      } else if (mapSatelliteOpacity == 1) {
                        setLightStyle(map);
                        this.className = "mapboxgl-ctrl-icon satellite-style-button";
                        this.title = "Show Satellite";
                      }
                    };

                    this._container = document.createElement("div");
                    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
                    this._container.appendChild(this._btn);

                    return this._container;
                  }
                },
                {
                  key: "onRemove",
                  value: function onRemove() {
                    this._container.parentNode.removeChild(this._container);
                    this._map = undefined;
                  }
                }
              ]);

              return StyleToggle;
            })();

            // Reset map view and url
            var HomeButton = ( () => {
              function HomeButton(_ref) {
                _classCallCheck(this, HomeButton);
              }

              _createClass(HomeButton, [
                {
                  key: "onAdd",
                  value: function onAdd(map) {
                    this._map = map;
                    var _this = this;
                    this._btn = document.createElement("button");
                    this._btn.type = "button";
                    this._btn.className = "mapboxgl-ctrl-icon home-button";
                    this._btn["aria-label"] = "Reset Map View";
                    this._btn.title = "Reset Map View";
                    this._btn.onclick = function() {
                        that.flyToLandingPage();
                    };

                    this._container = document.createElement("div");
                    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
                    this._container.appendChild(this._btn);

                    return this._container;
                  }
                },
                {
                  key: "onRemove",
                  value: function onRemove() {
                    this._container.parentNode.removeChild(this._container);
                    this._map = undefined;
                  }
                }
              ]);

              return HomeButton;
            })();

            this.map.addControl(new mapboxgl.NavigationControl());
            this.map.addControl(new StyleToggle({}));
            this.map.addControl(new HomeButton({}));

            // geocoder on the map #map-geocoder
            var geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                marker: false,
                bbox: [-3.0, 52.3, 0.1, 54.5],
                placeholder: "Search location",
                minLength: 3,
                limit: 15,
                zoom: 17,
                types: 'country,region,postcode,district,place,locality,neighborhood,address',
            });
            document.getElementById('map-geocoder').appendChild(geocoder.onAdd(this.map));

            this.map.on('load', () => {
                var sent1 = ["==", ["get", "sentiment"], 1];
                var sent2 = ["==", ["get", "sentiment"], 2];
                var sent3 = ["==", ["get", "sentiment"], 3];
                var sent4 = ["==", ["get", "sentiment"], 4];
                var sent5 = ["==", ["get", "sentiment"], 5];
                var sent6 = ["==", ["get", "sentiment"], 6];

                this.map.addSource('sentiment_data', {
                    "type": "geojson",
                    "data": window.mapFeatures,
                    "cluster": true,
                    "clusterMaxZoom": 15,
                    "clusterRadius": 45,
                    "clusterProperties": {
                        "sent1": ["+", ["case", sent1, 1, 0]],
                        "sent2": ["+", ["case", sent2, 1, 0]],
                        "sent3": ["+", ["case", sent3, 1, 0]],
                        "sent4": ["+", ["case", sent4, 1, 0]],
                        "sent5": ["+", ["case", sent5, 1, 0]],
                        "sent6": ["+", ["case", sent6, 1, 0]],
                    }
                });

                this.map.addLayer({
                    "id": "sentiment_circle",
                    "type": "circle",
                    "source": "sentiment_data",
                    "filter": ["!=", "cluster", "true"],
                    "paint": {
                        "circle-color": ["case",
                            sent1, this.colors[1],
                            sent2, this.colors[2],
                            sent3, this.colors[3],
                            sent4, this.colors[4],
                            sent5, this.colors[5],
                            sent6, this.colors[6], this.colors[5]],
                        "circle-opacity": 0.8,
                        "circle-radius": 12,
                    },
                });

                this.map.addSource('covid_data', {
                    "type": "geojson",
                    "data": window.covidFeatures,
                    "cluster": true,
                    "clusterMaxZoom": 15,
                    "clusterRadius": 45,
                    clusterProperties: {
                      "sent1": ["+", ["case", sent1, 1, 0]],
                      "sent2": ["+", ["case", sent2, 1, 0]],
                      "sent3": ["+", ["case", sent3, 1, 0]],
                      "sent4": ["+", ["case", sent4, 1, 0]],
                      "sent5": ["+", ["case", sent5, 1, 0]],
                      "sent6": ["+", ["case", sent6, 1, 0]],
                    }
                });

                this.map.addLayer({
                    "id": "covid_comments",
                    "type": "circle",
                    "source": "covid_data",
                    "filter": ["!=", "cluster", "true"],
                    "paint": {
                      "circle-color": ["case",
                      sent6, this.colors[6], this.colors[6]],
                      "circle-opacity": 0.8,
                      "circle-radius": 12,
                    }
                });

                this.mapLoaded = true;

                if(this.commentLatLng !== null){
                    this.moveToMarker(this.commentLatLng);
                }

                this.switchState(this.$store.state.mode);

                // Change the cursor to a pointer when the mouse is over marker
                for(var i = 0; i < this.mapLayers.length; i++)
                {
                  this.map.on('mouseenter', this.mapLayers[i], () => {
                      this.map.getCanvas().style.cursor = 'pointer';
                  });

                  this.map.on('mouseleave', this.mapLayers[i], () => {
                      this.map.getCanvas().style.cursor = 'grab';
                  });
                }

            });

            this.map.on('data', (e) => {
                if (e.dataType === "style") {
                    this.updateMarkers();
                    return;
                }

                if (e.sourceId !== 'sentiment_data' || !e.isSourceLoaded) return;
                this.map.on('move', this.updateMarkers);
                this.map.on('moveend', this.updateMarkers);
                this.updateMarkers();
            });

            // Map click events for map markers
            for(var i = 0; i < this.mapLayers.length; i++)
            {
              this.map.on('click', this.mapLayers[i], e => {
                  let lat =  e.features[0].geometry.coordinates[0];
                  let lng = e.features[0].geometry.coordinates[1];

                  e.target.flyTo({
                      zoom: 18.9,
                      pitch: 0,
                      bearing: 0,
                      center: [lat, lng],
                      speed: 0.9
                      });

                      this.$router.push({ name: "comment-view-page", params: { id: e.features[0].properties.id } }).catch(err => {});
                });
            }
            //this.togglePhases();
        }
    }
</script>
