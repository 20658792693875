<template>
    <div class="container-fluid landing-page">
        <h1>Welcome to the Sheffield City Region’s Active Travel Map</h1>
        <p><strong>The South Yorkshire Active Travel Map is now closed for new comments.</strong></p><p>It was set up in 2019 when Dame Sarah Storey was appointed as Commissioner and was used to develop the Active Travel Implementation Plan, from which the 2040 network plan accounted for over 94% of the comments. The four local authorities continue to run consultations on new active travel schemes. </p>
        <p>We are now working hard to implement the programme of Active Travel Lanes and Neighbourhoods and will report annually on progress. </p>
        <p>You can still read the original comments, and if you have feedback please contact your local authority.</p><p>Contact us at <a href="mailto:cyclingwalking@sheffieldcityregion.org.uk">cyclingwalking@sheffieldcityregion.org.uk</a> if you want to talk to us about the strategy or funding.</p>
        <!--<p style="font-weight: bold">During the Covid-19 pandemic we are inviting new and existing users to add comments where trial infrastructure could be placed to support active travel.</p>
        <p style="font-weight: bold">New comments can be where it is difficult to maintain social distancing, or where you are making longer trips by active travel.</p>
        <p>All the existing comments can be toggled on from the map key in the top left of the map. These have already been analysed as part of the Active Travel Implementation Plan which will be published soon.</p>
        <!-- <p>This map is for everyone who wants to make journeys on foot or by bike across our region, for any purpose, including those who don’t walk or cycle often and people who use mobility aids.</p>
        <p>We would like your comments on the infrastructure we already have, to point out what works well and what doesn’t.</p>
        <p>Comments will then be used, alongside those we have already gathered from transport and planning experts, to help plan the Sheffield City Region Active Travel Network. This network will be planned with people, not cars, as the priority, with routes that are safe and accessible for all.</p> -->

        <!-- <div class="collapsable collapsable--column">
            <div class="collapsable__content">
                <img id="landing-page__img" src="/images/learn-more--image.jpg" alt="">
                <p><b>Dame Sarah Storey, Active Travel Commissioner</b></p>
                <p>"I'm delighted to share with you the opportunity to contribute to our mission of creating the best region-wide network for active travel.</p>
                <p>This map is for everyone who lives or works in the Sheffield City Region and I want to hear from everyone about their experience of the roads and footways as they are now.</p>
                <p>For those who already make journeys on foot or by bike, for the many multitude of reasons that you do, I want to hear about these journeys. How could they be improved?</p>
                <p>For those who are unable to leave their car behind and feel they currently have no choice but to drive, I want to hear about the barriers to the choice you are making and what changes could be made to give you the option of choosing to make your shortest journeys on foot, or even one day by bike.</p>
                <p>For those parents who struggle with buggies and worry about when their toddlers want to run on ahead, or the teenagers who don't feel safe walking to school and people using wheelchairs or have mobility aids, what are your journeys like now? And how could they be improved and made safer? Do you face challenges on what should be the simplest of journeys?</p>
                <p>This map is for everyone to make a contribution, which will allow my team and I to improve the region’s roads and footways for the future. Everyone's view will help us in planning and creating an Active Travel Network that is fit for the 21st century and will last us for many generations to come.</p>
                <p>Thank you in advance for sharing your thoughts and experiences, don't forget to contribute to the conversation on Twitter by following <a href="https://twitter.com/cyclewalkscr" target="_blank">@CycleWalkSCR</a> and using <a href="https://twitter.com/search?q=%23CycleWalkSCR" target="_blank">#CycleWalkSCR</a>"</p>

                <p><b>We want to know what you think about:</b></p>
                <p><ul>
                    <li>Junctions and crossings – do cars, pedestrians or cyclists take priority? Are they easy to navigate? How long are you waiting for?</li>
                    <li>Footpaths and cycle routes – are they well lit? Are they accessible for people with prams, using wheelchairs or adapted bikes? Do they feel safe?</li>
                    <li>Accessibility – can you travel safely using a wheelchair or adapted bike? Can you travel safely if you are visually impaired? Can you travel safely if you have mobility issues?</li>
                    <li>Your commute – do you use multiple modes of transport to get to work? We want to hear about your experiences. Do you regularly take your bike on the train? Do you walk to the bus stop? Tell us about your journey.</li>
                    <li>Pavements – are pavements wide enough for buggies and wheelchairs? Are dropped curbs fit for purpose?</li>
                    </ul>
                </p>

                <p>Of course there will be more to comment on, and we want to hear it all. Your comments may bring in other aspects of your journey. You may, for example, walk for 20 minutes to your bus stop, we want to hear about this. Walk the kids to school? Tell us what they think of the journey.</p>
                <p>If you don’t currently cycle or walk as your main form of transport, we want to know why. Tell us what you need to feel able to leave the car at home.</p>

                <p><b>How does the map work?</b></p>
                <p>To make a comment on the map, select the area you wish to comment on. This could be a particular crossing, footpath, cycle route or junction.</p>
                <p>Your comment will be anonymous and other users will be able to agree with your comment by liking it. Comments can also be shared using <a href="https://twitter.com/search?q=%23CycleWalkSCR" target="_blank">#CycleWalkSCR</a> , to start the conversation on social media.</p>
                <p>To make a comment on the map, you will need to make an account. This takes just a few minutes and stops robots or fake accounts commenting on the map. Your details and data will be kept safe according to our <a target="_blank" href="/privacy-policy">privacy policy</a>.</p>

            </div> -->
            <div id="landing-page__nav">
                <a href="https://sheffieldcityregion.org.uk/cyclewalkscr/" class="btn" target="_blank">Visit the project website</a>
                <!-- <router-link to="/add-comment" class="btn btn-orange btn-comment">Add a comment</router-link> -->
            </div>
        <!-- </div> -->
        <div class="landing-footer land">
                  Built on <a href="https://deetu.com/" target="_blank">Deetu Engage</a> -
                  <a href="mailto:engage@deetu.com?Subject=Engage Map Feedback" target="_top"> Provide feedback.</a>
                </div>
    </div>
</template>

<script>
    export default {
        mounted() {
        },
        methods: {
            toggleLearnMore(event) {
                var content = event.target.parentElement.previousElementSibling;
                if (content.style.maxHeight) {
                    content.style.maxHeight = null;
                } else {
                    content.style.maxHeight = "100%";
                }
            }
        },
    }
</script>
